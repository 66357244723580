import styles from "assets/jss/material-kit-react/views/profilePage.js";
import classNames from "classnames";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import Header from "components/Header/Header.js";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Lessons from "components/Lessons";
import Parallax from "components/Parallax/Parallax.js";
import Danger from "components/Typography/Danger.js";
import React, { useEffect, useState } from "react";
import firebase from "util/firebase";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function GroupLessonsPage(props) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    firebase.analytics().logEvent("grouplessons_page_view");
  }, []);

  const handleLoading = (isLoading) => {
    setLoading(isLoading);
  };

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        style={{ zIndex: "2147483647" }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header
        color="transparent"
        brand="425 Kids Chess"
        leftLinks={<HeaderLeftLinks />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <div className={classes.description}>
              <h2 className={classes.title}>Here are our lessons</h2>
              <p>
                In our chess group lessons, students new to chess will learn in
                small groups with our instructors. Our chess group lessons cover
                chess concepts that are invaluable for beginners, and will help
                them get started playing chess. Students will work together to
                solve puzzles, and compete in other engaging activities.
              </p>
              <p><Danger>Please click <a href='https://docs.google.com/forms/d/e/1FAIpQLScBQmu8Rj_TF3yzTzz96D3JkOs37RriPL5yGFnlVmowpZVVUw/viewform' target="_blank">here</a> to suggest alternate group lesson dates or time not listed below.</Danger></p>
            </div>
            <GridContainer justify="center">
              <Lessons lessontype="group" loadingHandler={handleLoading} />
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
