import footerStyles from "assets/jss/material-kit-react/components/footerStyle.js";
import modalStyle from "assets/jss/material-kit-react/modalStyle.js";
import classNames from "classnames";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { withSnackbar } from "components/withSnackbar";
import PropTypes from "prop-types";
import React, { useState } from "react";
import API from "util/api";

import { List, ListItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Email from "@material-ui/icons/Email";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styles = {
  ...footerStyles,
  ...modalStyle,
};

const useStyles = makeStyles(styles);

function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });

  const [errors] = useState([]);
  const [modal, setModal] = useState(false);
  const [form, setForm] = useState({});

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = () => {
    let options = {
      url: "/mailinglist",
      method: "post",
      data: form,
    };

    API(options)
      .then(() => {
        setModal(false);
        props.snackbarShowMessage(
          "Thank you for joining our mailing list!",
          "success"
        );
      })
      .catch((err) => {
        console.log(err);
        props.snackbarShowMessage("Sign up failed!", "error");
      });
  };

  return (
    <div>
      <footer className={footerClasses}>
        <div className={classes.container}>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="#"
                  className={classes.block}
                  onClick={() => setModal(true)}
                >
                  Sign-up for Newsletter
                </a>
              </ListItem>
            </List>
          </div>
          <div className={classes.right}>
            {/* &copy; {1900 + new Date().getYear()} , made with <Favorite className={classes.icon} /> 面倒臭い */}
            &copy; {1900 + new Date().getYear()}
          </div>
        </div>
      </footer>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <Card>
            <form className={classes.form}>
              <CardHeader color="primary" className={classes.cardHeader}>
                <h4>Get News from 425 Kids Chess</h4>
              </CardHeader>
              <CardBody>
                Sign up for email to get special news from 425 Kids Chess.
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "email",
                    placeholder: errors.email ? errors.email : "",
                    error: errors.email ? true : false,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    onChange: (e) => handleChange(e),
                  }}
                />
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                {/* <Link href="signup-page">{"Don't have an account? Sign up"}</Link> */}
              </CardFooter>
            </form>
          </Card>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={handleSubmit} color="success">
            Sign up
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};

export default withSnackbar(Footer);
