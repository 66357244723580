import React from "react";
import ReactDOM from "react-dom";
//import './index.css';
import App from "./App";
import firebase from "./util/firebase";

console.log(`Currently in ${process.env.NODE_ENV} environment.`);

// if(process.env.NODE_ENV==='production'){
//   //firebase.analytics();
// }

firebase.analytics();
firebase.auth();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
