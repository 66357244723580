import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import Button from "components/CustomButtons/Button.js";
import { useAuth } from "contexts/AuthContext";
import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);
export default function HeaderLinks() {
  const classes = useStyles();
  let history = useHistory();
  const { logout } = useAuth();

  function signOut() {
    logout()
      .then(() => {
        // Sign-out successful.
        localStorage.removeItem("AuthToken");
        localStorage.removeItem("FirstName");
        localStorage.removeItem("UserName");
        history.push("/");
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  }

  function signIn() {
    console.log("SignIn!");
    history.push("/login-page");
  }

  let button;
  if (localStorage.getItem("AuthToken") === null) {
    console.log("logged in!");
    button = (
      <Button color="transparent" className={classes.navLink} onClick={signIn}>
        Sign in
      </Button>
    );
  } else {
    console.log("logged out!");
    button = (
      <Button color="transparent" className={classes.navLink} onClick={signOut}>
        Sign out
      </Button>
    );
  }

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          component={Link}
          to="/group-lessons-page"
          color="transparent"
          className={classes.navLink}
        >
          Small Group Lessons
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          component={Link}
          to="/oneonone-lessons-page"
          color="transparent"
          className={classes.navLink}
        >
          1-on-1 Lessons
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          component={Link}
          to="/profile-page"
          color="transparent"
          className={classes.navLink}
        >
          About Us
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          component={Link}
          to="/contact-page"
          color="transparent"
          className={classes.navLink}
        >
          Contact Us
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>{button}</ListItem>
    </List>
  );
}
