import styles from "assets/jss/material-kit-react/views/loginPage.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import { withSnackbar } from "components/withSnackbar";
import { useAuth } from "contexts/AuthContext";
import React, { useState } from "react";
import { withRouter } from "react-router";
import API from "util/api";
import { validateLoginData } from "util/validators";

import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import withStyles from "@material-ui/core/styles/withStyles";
import Email from "@material-ui/icons/Email";

function Login(props) {
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState({});
  const { login, getIdToken } = useAuth();
  const { classes } = props;

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.loadingHandler(true);

    const user = {
      email: form.email,
      password: form.password,
    };

    const { valid, errors } = validateLoginData(user);
    setErrors({ errors: errors });

    if (!valid) {
      console.log(errors);
      props.snackbarShowMessage("Log in failed. Please try again!", "error");
      return;
    }

    login(form.email, form.password)
      .then((userCredential) => {
        return getIdToken().then((idToken) => {
          localStorage.setItem("AuthToken", `Bearer ${idToken}`);
          API.defaults.headers.common = { Authorization: `Bearer ${idToken}` };
          return API.get("/user").then((response) => {
            console.log(response.data);
            localStorage.setItem(
              "FirstName",
              `${response.data.userCredentials.firstName}`
            );
            localStorage.setItem(
              "UserName",
              `${response.data.userCredentials.username}`
            );
            props.loadingHandler(false);
            props.history.push({
              pathname: "/",
              state: {
                snackbarOpen: true,
                snackbarMessage: "Log in successful!",
                snackbarSeverity: "success",
              },
            });
          });
        });
      })
      .catch((error) => {
        console.log(error);
        props.snackbarShowMessage("Log in failed. Please try again!", "error");
        props.loadingHandler(false);
      });
  };

  return (
    <GridItem xs={12} sm={12} md={4}>
      <Card>
        <form className={classes.form}>
          <CardHeader color="primary" className={classes.cardHeader}>
            <h4>Login</h4>
          </CardHeader>
          <CardBody>
            <CustomInput
              labelText="Email..."
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "email",
                placeholder: errors.email ? errors.email : "",
                error: errors.email ? true : false,
                endAdornment: (
                  <InputAdornment position="end">
                    <Email className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                onChange: (e) => handleChange(e),
              }}
            />
            <CustomInput
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "password",
                placeholder: errors.password ? errors.password : "",
                error: errors.password ? true : false,
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className={classes.inputIconsColor}>
                      lock_outline
                    </Icon>
                  </InputAdornment>
                ),
                autoComplete: "off",
                onChange: (e) => handleChange(e),
              }}
            />
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button simple color="primary" size="lg" onClick={handleSubmit}>
              Log in
            </Button>
          </CardFooter>
          <CardFooter className={classes.cardFooter}>
            <Link href="signup-page">{"Don't have an account? Sign up"}</Link>
          </CardFooter>
        </form>
      </Card>
    </GridItem>
  );
}
export default withSnackbar(withRouter(withStyles(styles)(Login)));
