import React, { useEffect, useState } from "react";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Login from "components/Login";
import firebase from "util/firebase";
import image from "assets/img/bg8.jpg";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/loginPage.js";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [loading, setLoading] = useState(false);
  const [, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    firebase.analytics().logEvent("login_page_view");
  }, []);

  const handleLoading = (isLoading) => {
    setLoading(isLoading);
  };

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        style={{ zIndex: "2147483647" }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header
        absolute
        color="transparent"
        brand="425 Kids Chess"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message="I love snacks"></Snackbar> */}
        <div className={classes.container}>
          <GridContainer justify="center">
            {/* <Login onSubmit={handleSubmitResult}/> */}
            <Login loadingHandler={handleLoading} />
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
