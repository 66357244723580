import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import CounterCard from "components/CounterCard/CounterCard";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={2}>
            <CounterCard
              count={300}
              suffix="+"
              title="Students"
              color="dimgray"
              textColor="white"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CounterCard
              count={800}
              suffix="+"
              title="Hours"
              color="dimgray"
              textColor="white"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CounterCard
              count={50}
              title="Instructors"
              color="dimgray"
              textColor="white"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CounterCard
              count={14}
              title="States"
              color="dimgray"
              textColor="white"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CounterCard
              count={7}
              title="Countries"
              color="dimgray"
              textColor="white"
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Who We Are</h2>
            <h5 className={classes.description}>
              425 Chess Kids is a group of highschool and middle school students
              offering to educate kids about chess. We have all played countless
              years of chess, and possess a deep understanding of the game
              allowing us to properly train younger students. We believe that
              chess is an invaluable activity for kids to learn, which is why we
              are providing FREE small groups and 1-on-1 lessons to everybody.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <InfoArea
                image={require("assets/img/video-call-outline.svg")}
                title="Group Lessons"
                description="In our chess group lessons, students new to chess will learn in small groups with our instructors. Our chess group lessons cover chess concepts that are invaluable for beginners, and will help them get started playing chess. Students will work together to solve puzzles, and compete in other engaging activities."
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                image={require("assets/img/video-tutorial-outline.svg")}
                title="1-on-1 Lessons"
                description="Learn chess 1-on-1 with a skilled player! Sign up to play and receive mentoring from an experienced player, to hone your skills! Our instructors will fully devote their time to help improve our students as much as possible."
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
