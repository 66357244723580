import React, { useEffect } from "react";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import firebase from "util/firebase";
import image from "assets/img/bg7.png";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { withRouter } from "react-router";
import { withSnackbar } from "components/withSnackbar";

const useStyles = makeStyles(styles);

function EmailVerification(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    firebase.analytics().logEvent("emailverification_page_view");
  }, []);

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="425 Kids Chess"
        leftLinks={<HeaderLeftLinks />}
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>Thank you for signing up with us!</h4>
                </CardHeader>
                <CardBody>
                  <h5>
                    A verification link has been sent to your email account.
                  </h5>
                  <p>
                    Please click on the link that has just been sent to your
                    email account to verify your email and finish the sign up
                    process.
                  </p>
                </CardBody>
                <CardFooter className={classes.cardFooter}></CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
export default withSnackbar(withRouter(EmailVerification));
