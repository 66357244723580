import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);
export default function HeaderLeftLinks() {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      <ListItem
        className={classes.listItem}
        style={{ fontSize: "16px", fontWeight: "400" }}
      >
        <div>
          {localStorage.getItem("FirstName") === null
            ? null
            : `Hi, ${localStorage.getItem("FirstName")}!`}
        </div>
      </ListItem>
    </List>
  );
}
