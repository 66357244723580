import alec from 'assets/img/faces/alec.jpg';
import anson from 'assets/img/faces/anson.jpg';
import avatar from 'assets/img/faces/avatar.jpg';
import brandon from 'assets/img/faces/brandon.jpg';
import dylan from 'assets/img/faces/dylan.jpg';
import elbert from 'assets/img/faces/elbert.jpg';
import ethan from 'assets/img/faces/ethan.jpg';
import evan from 'assets/img/faces/evan.jpg';
import joshua from 'assets/img/faces/joshua.jpeg';
import kai from 'assets/img/faces/kai.png';
import lam from 'assets/img/faces/lam.jpg';
import sherlock from 'assets/img/faces/sherlock.jpg';
import { cardSubtitle, cardTitle } from 'assets/jss/material-kit-react.js';
import teamStyles from 'assets/jss/material-kit-react/views/landingPageSections/teamStyle.js';
import classNames from 'classnames';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const styles = {
    ...teamStyles,
    cardTitle,
    cardSubtitle,
};

const useStyles = makeStyles(styles);

export default function TeamSection() {
    const classes = useStyles();
    const imageClasses = classNames(classes.imgRaised, classes.imgRounded, classes.imgFluid);
    return (
        <div className={classes.section}>
            <div className={classes.section}>
                <h2 className={classes.title}>Here is our team</h2>
                <div>
                <GridContainer justify='center'>
                    <GridItem xs={12} sm={12} md={6}>
                        <CardHeader image>
                            <img src={dylan} alt='' className={imageClasses} />
                            <div
                                className={classes.coloredShadow}
                                style={{
                                    backgroundImage: 'url(' + { dylan } + ')',
                                    opacity: '1',
                                }}
                            />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Dylan Hsueh</h4>
                            <span className='text'>
                                Hi, I’m Dylan Hsueh. I’m a sophomore at the University of Washington. I have some
                                teaching experience from working at summer camps as a teaching assistant. I first
                                started playing chess since elementary school. Since then, I have enjoyed playing many
                                matches of chess at Chess 4 Life, online, and against my friends. I believe that chess
                                is a fun activity for everyone which can help develop intellectual skills.
                            </span>
                        </CardBody>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CardHeader image>
                            <img src={ethan} alt='' className={imageClasses} />
                            <div
                                className={classes.coloredShadow}
                                style={{
                                    backgroundImage: 'url(' + { ethan } + ')',
                                    opacity: '1',
                                }}
                            />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Ethan Luo</h4>
                            <span className='text'>
                                Hi, my name is Ethan Luo and I’m a current 12th Grader at Newport High School. I have
                                played over a thousand of games of chess, as well as finished 12th in the state at the
                                2021 Washington State High School Championship and am currently board 3 for the Newport
                                High School Chess team, where I helped our school qualify to state twice and finish 4th
                                at the state championship. Chess has taught me countless life-long skills, and I aim to
                                help teach them to others through chess.
                            </span>
                        </CardBody>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CardHeader image>
                            <img src={brandon} alt='' className={imageClasses} style={{ height: '644px' }} />
                            <div
                                className={classes.coloredShadow}
                                style={{
                                    backgroundImage: 'url(' + { brandon } + ')',
                                    opacity: '1',
                                }}
                            />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Brandon Luo</h4>
                            <span className='text'>
                                Hi, I'm Brandon Luo. I'm a sophomore at Newport High School and I've been playing chess
                                for over a decade. I have qualified for the Washington State Championship multiple times
                                and have taught over 100 hours of chess instruction. Chess is a really important part of
                                my life and I hope you will enjoy it as well.
                            </span>
                        </CardBody>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CardHeader image>
                            <img src={evan} alt='' className={imageClasses} />
                            <div
                                className={classes.coloredShadow}
                                style={{
                                    backgroundImage: 'url(' + { evan } + ')',
                                    opacity: '1',
                                }}
                            />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Evan Hsueh</h4>
                            <span className='text'>
                                Hey, I'm Evan Hsueh. I'm currently a junior at Bellevue High School. I started learning
                                chess in elementary school, and continued playing occasionally as a hobby. I believe chess
                                offers many opportunities to develop and employ strategic analysis and learning chess
                                creates many opportunities for social interactions and critical thinking.
                            </span>
                        </CardBody>
                    </GridItem>
                </GridContainer>
                </div>
            </div>
            <GridContainer justify='center'>
                <Card>
                    <CardBody>
                        <CardHeader color='warning'>Featuring</CardHeader>
                        <div className={classes.section}>
                            <GridContainer justify='center'>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CardHeader image>
                                        <img src={sherlock} alt='' className={imageClasses} />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: 'url(' + { sherlock } + ')',
                                                opacity: '1',
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>Sherlock Chen, Ohio</h4>
                                        <h6 className={classes.cardSubtitle}>Regional director</h6>
                                    </CardBody>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CardHeader image>
                                        <img src={joshua} alt='' className={imageClasses} />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: 'url(' + { joshua } + ')',
                                                opacity: '1',
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>Joshua Moon, Maryland</h4>
                                        <h6 className={classes.cardSubtitle}>Regional director</h6>
                                    </CardBody>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.section}>
                            <GridContainer justify='center'>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CardHeader image>
                                        <img src={alec} alt='' className={imageClasses} />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: 'url(' + { alec } + ')',
                                                opacity: '1',
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>Alec Tang, Indiana</h4>
                                        <h6 className={classes.cardSubtitle}>Regional director</h6>
                                    </CardBody>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CardHeader image>
                                        <img src={elbert} alt='' className={imageClasses} />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: 'url(' + { elbert } + ')',
                                                opacity: '1',
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>Elbert Zeng, California</h4>
                                        <h6 className={classes.cardSubtitle}>Regional director</h6>
                                        <span className='text'></span>
                                    </CardBody>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CardHeader image>
                                        <img src={kai} alt='' className={imageClasses} />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: 'url(' + { kai } + ')',
                                                opacity: '1',
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>Kai Pisan, Washington</h4>
                                        <h6 className={classes.cardSubtitle}>Regional director</h6>
                                    </CardBody>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.section}>
                            <GridContainer justify='center'>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CardHeader image>
                                        <img src={anson} alt='' className={imageClasses} />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: 'url(' + { anson } + ')',
                                                opacity: '1',
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>Anson Cao, New York</h4>
                                        <h6 className={classes.cardSubtitle}>Communications director</h6>
                                    </CardBody>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CardHeader image>
                                        <img src={avatar} alt='' className={imageClasses} />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: 'url(' + { avatar } + ')',
                                                opacity: '1',
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>Awab Tarigah, United Arab Emirates</h4>
                                        <h6 className={classes.cardSubtitle}>Regional director</h6>
                                    </CardBody>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CardHeader image>
                                        <img src={lam} alt='' className={imageClasses} />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage: 'url(' + { lam } + ')',
                                                opacity: '1',
                                            }}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>
                                            Lam Nguyen, Virginia, Communications director
                                        </h4>
                                        <h6 className={classes.cardSubtitle}>Communications director</h6>
                                    </CardBody>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <h4 className={classes.cardTitle}>Meet our other instructors</h4>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer justify='center'>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Kiann Skkandann</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>Washington</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Ryan Everding</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>Ohio</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Saket Resika</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>Washington</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Son Nguyen</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>New Jersey</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Tuan Nguyen</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>Virginia</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Saumrita Joshi</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>Washington</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Elijah Sosa</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>California</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Rachel Song</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>California</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Ishaan Agrawal</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>Washington</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Sachin Miskelly</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>Washington</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Joshil Vaddi</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>Georgia</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Ashwin Kaliyaperumal</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>Washington</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Cian Moss</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>Colorado</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Thi Do</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>Canada</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'right' }}>
                                        <span role='img' aria-labelledby='crown'>
                                            👑
                                        </span>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ fontWeight: 'bold' }}>Aarush Shah</div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <div style={{ textAlign: 'left' }}>Washington</div>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </CardBody>
                </Card>
            </GridContainer>
        </div>
    );
}
