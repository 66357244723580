import { cardTitle } from "assets/jss/material-kit-react.js";
// core components
import styles from "assets/jss/material-kit-react/components/cardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
import CountUp from "react-countup";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function CounterCard({
  color,
  count,
  title,
  textColor,
  ...rest
}) {
  const classes = useStyles();
  return (
    <Card style={{ backgroundColor: "" + color + "" }}>
      <CardBody style={{ color: "" + textColor + "" }}>
        <h2 style={{ fontWeight: "900" }}>
          <CountUp end={count} duration={1} {...rest} />
        </h2>
        <h5>{title}</h5>
      </CardBody>
    </Card>
  );
}

// Setting default props for the CounterCard
CounterCard.defaultProps = {
  color: "info",
  title: "",
};

CounterCard.propTypes = {
  color: PropTypes.string,
  count: PropTypes.number.isRequired,
  title: PropTypes.string,
  textColor: PropTypes.string,
};
