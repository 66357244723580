import React, { useEffect, useState } from "react";

import API from "util/api";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import classNames from "classnames";
import firebase from "util/firebase";
import landingPageStyles from "assets/jss/material-kit-react/views/landingPage.js";
import { makeStyles } from "@material-ui/core/styles";
import workStyles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const styles = {
  ...landingPageStyles,
  ...workStyles,
};

const useStyles = makeStyles(styles);

export default function ContactPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [formData, setFormData] = useState({});

  useEffect(() => {
    firebase.analytics().logEvent("contact_page_view");
  }, []);

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail();
  };

  const sendEmail = () => {
    let options = {
      url: "/contactform",
      method: "post",
      data: formData,
    };

    API(options)
      .then((response) => {
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
        props.history.push("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Header
        color="transparent"
        brand="425 Kids Chess"
        leftLinks={<HeaderLeftLinks />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>Get in touch with us</h2>
                <h4 className={classes.description}>
                  Please contact us at{" "}
                  <a href="mailto:425kidschess@gmail.com">
                    425kidschess@gmail.com
                  </a>
                  , or use this form below to send us a message. We will get in
                  touch as soon as we can.
                </h4>
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Your Name"
                        id="name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: updateInput,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Your Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: updateInput,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Subject"
                        id="subject"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: updateInput,
                        }}
                      />
                    </GridItem>
                    <CustomInput
                      labelText="Your Message"
                      id="message"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.textArea,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        onChange: updateInput,
                      }}
                    />
                    <GridItem xs={12} sm={12} md={4}>
                      <Button color="primary" type="submit">
                        Send Message
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
