import { cardTitle, title } from "assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const teamStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d",
  },
  description: {
    color: "#999",
  },
  justifyCenter: {
    justifyContent: "center !important",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999",
  },
  margin5: {
    margin: "5px",
  },
  ".container-all": {
    width: "fit-content",
    margin: "20px auto",
    height: "auto",
  },
  ".container": {
    width: "calc(33% - 6px)",
    overflow: "hidden",
    height: "fit-content",
    margin: "3px",
    padding: "0",
    display: "block",
    position: "relative",
    cssFloat: "left",
  },
  img: {
    width: "100%",
    transitionDuration: ".3s",
    maxWidth: "100%",
    display: "block",
    overflow: "hidden",
    cursor: "pointer",
  },
  ".title": {
    position: "absolute",
    display: ["block", "none"],
    cursor: "pointer",
    top: "35%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    fontWeight: "bold",
    fontSize: "1.6em",
    textShadow: "1px 5px 10px black",
    transitionDuration: ".3s",
  },
  ".text": {
    position: "absolute",
    top: "70%",
    cursor: "pointer",
    maxWidth: "80%",
    textAlign: "center",
    left: "50%",
    textShadow: "1px 5px 10px black",
    fontSize: "1em",
    display: "none",
    marginRight: "-50%",
    transitionDuration: ".3s",
    transform: "translate(-50%, -50%)",
  },
  ".container:hover img": {
    transform: "scale(1.2)",
    transitionDuration: ".3s",
    filter: "grayscale(50%)",
    opacity: 0.7,
  },
  ".container:hover span": {
    color: "white",
    display: "block",
    transitionDuration: ".3s",
  },
  "@media only screen and (max-width: 900px)": {
    ".container": { width: "calc(50% - 6px)" },
  },
  "@media only screen and (max-width: 400px)": {
    ".container": { width: "100%" },
  },
};

export default teamStyle;
