import "assets/scss/material-kit-react.scss?v=1.9.0";

import { AuthProvider } from "contexts/AuthContext";
import { createBrowserHistory } from "history";
import ContactPage from "pages/ContactPage";
import EmailVerification from "pages/EmailVerification";
import GroupLessonsPage from "pages/GroupLessonsPage";
import LoginPage from "pages/LoginPage";
import OneOnOneLessonsPage from "pages/OneOnOneLessonsPage";
import SignupPage from "pages/SignupPage";
import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.grey[200],
//     width:"100vw",
//     height:"100vh",
//     paddingTop:theme.spacing(5)
//   },
// }));

var hist = createBrowserHistory();

function App() {
  //const classes = useStyles();
  return (
    <Router history={hist}>
      <AuthProvider>
        <Switch>
          <Route path="/landing-page" component={LandingPage} />
          <Route path="/profile-page" component={ProfilePage} />
          <Route path="/contact-page" component={ContactPage} />
          <Route path="/login-page" component={LoginPage} />
          <Route path="/signup-page" component={SignupPage} />
          <Route path="/email-verification" component={EmailVerification} />
          <Route path="/group-lessons-page" component={GroupLessonsPage} />
          <Route
            path="/oneonone-lessons-page"
            component={OneOnOneLessonsPage}
          />
          <Route path="/" component={LandingPage} />
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;
