import { cardTitle } from "assets/jss/material-kit-react.js";
import modalStyle from "assets/jss/material-kit-react/modalStyle.js";
import landingPageStyles from "assets/jss/material-kit-react/views/landingPage.js";
import workStyles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import classNames from "classnames";
import Badge from "components/Badge/Badge.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import { withSnackbar } from "components/withSnackbar";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import API from "util/api";
import { authMiddleWare } from "util/auth";
import firebase from "util/firebase";

import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import VideocamIcon from "@material-ui/icons/Videocam";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styles = {
  formControl: {
    minWidth: 120,
  },
  ...landingPageStyles,
  ...workStyles,
  ...modalStyle,
  cardTitle,
};

const useStyles = makeStyles(styles);

function filterNextFewDays(date) {
  var a = dayjs(date);
  var b = dayjs();

  return a.diff(b, "days") < 7;
}

const hours = [
  { value: "13", text: "1:00 PM", disabled: false },
  { value: "14", text: "2:00 PM", disabled: false },
  { value: "15", text: "3:00 PM", disabled: false },
  { value: "16", text: "4:00 PM", disabled: false },
  { value: "17", text: "5:00 PM", disabled: false },
  { value: "18", text: "6:00 PM", disabled: false },
  { value: "19", text: "7:00 PM", disabled: false },
  { value: "20", text: "8:00 PM", disabled: false },
];

function OneOnOneLessonsPage(props) {
  // const [selectedDate, setDate] = useState(new Date());
  const [selectedDate, setDate] = useState(null);
  const [hour, setHour] = useState("");
  const classes = useStyles();
  const { ...rest } = props;
  const [formData, setFormData] = useState({});
  const [lessons, setLessons] = useState([]);
  const [modal, setModal] = useState(false);
  const [requestedTime, setRequestedTime] = useState(new Date());

  useEffect(() => {
    firebase.analytics().logEvent("oneononelessons_page_view");

    var currentTime = new Date(Date.now());
    var startTime = new Date(currentTime.getFullYear(), currentTime.getMonth());
    var endTime = new Date(
      startTime.getFullYear(),
      startTime.getMonth() + 1,
      0
    );

    API.get(`/lessonrequests/?startTime=${startTime}&endTime=${endTime}`)
      .then((response) => {
        console.log(response.data);
        setLessons(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleHourChange = (event) => {
    setHour(event.target.value);

    var adjustedTime = new Date(Date.parse(selectedDate));
    adjustedTime.setHours(event.target.value);
    adjustedTime.setMinutes(0);
    adjustedTime.setSeconds(0);

    console.log(`requestedTime: ${adjustedTime}`);
    setRequestedTime(adjustedTime);
  };

  const handleMonthChange = (event) => {
    console.log(`month changed: ${event}`);

    var startTime = new Date(Date.parse(event));
    var endTime = new Date(
      startTime.getFullYear(),
      startTime.getMonth() + 1,
      0
    );

    API.get(`/lessonrequests/?startTime=${startTime}&endTime=${endTime}`)
      .then((response) => {
        console.log(response.data);
        setLessons(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDateChange = (event) => {
    setDate(event);
    setHour("");

    console.log(`selectedDate: ${event}`);
    var date = new Date(Date.parse(event));
    console.log(`date: ${date}`);

    for (var i = 0; i < hours.length; i++) {
      console.log(hours[i]);
      var availableTime = new Date(date.setHours(parseInt(hours[i].value)));
      console.log(availableTime);
      var availableTimeYear = availableTime.getFullYear();
      var availableTimeMonth = availableTime.getMonth();
      var availableTimeDate = availableTime.getDate();
      var availableTimeHours = availableTime.getHours();
      hours[i].disabled = false;

      for (var lesson of lessons) {
        var classStartTime = new Date(Date.parse(lesson.classStartTime));
        console.log(`classStartTime: ${classStartTime}`);
        var classStartTimeYear = classStartTime.getFullYear();
        var classStartTimeMonth = classStartTime.getMonth();
        var classStartTimeDate = classStartTime.getDate();
        var classStartTimeHours = classStartTime.getHours();

        if (
          availableTimeYear === classStartTimeYear &&
          availableTimeMonth === classStartTimeMonth &&
          availableTimeDate === classStartTimeDate &&
          availableTimeHours === classStartTimeHours
        ) {
          hours[i].disabled = true;
          break;
        }
      }
    }
  };

  const handleEnrollOpen = () => {
    authMiddleWare(props.history);
    setModal(true);
  };

  const handleSignUpConfirmClick = () => {
    authMiddleWare(props.history);

    const newLessonRequestData = {
      classType: "OneOnOne",
      classStartTime: requestedTime,
      classDurationInMinutes: 60,
      notes: formData.notes,
    };

    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then((idToken) => {
        localStorage.setItem("AuthToken", `Bearer ${idToken}`);

        const authToken = localStorage.getItem("AuthToken");

        API.defaults.headers.common = { Authorization: `${authToken}` };
        return API.post(`/lessonrequests`, newLessonRequestData).then(() => {
          setModal(false);
          props.history.push({
            pathname: "/",
            state: {
              snackbarOpen: true,
              snackbarMessage: "Successfully requested 1-on-1 lesson!",
              snackbarSeverity: "success",
            },
          });
        });
      })
      .catch((err) => {
        console.log(err);
        props.snackbarShowMessage("1-on-1 lesson request failed!", "error");
      });
  };

  return (
    <div>
      <Header
        color="transparent"
        brand="425 Kids Chess"
        leftLinks={<HeaderLeftLinks />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={modal}
          TransitionComponent={Transition}
          keepMounted
          onClick={() => setModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => setModal(false)}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            <h4 className={classes.modalTitle}>Lesson Sign Up</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            Hi {localStorage.getItem("FirstName")}, you are about to request for
            a 1-on-1 lesson on
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={12}>
                <Card style={{ width: "30rem" }}>
                  <CardBody>
                    <h4 className={classes.cardTitle}>
                      {dayjs(requestedTime).format("dddd, MMM D YYYY h:mm A")}
                    </h4>
                    <span style={{ display: "inline", alignItems: "middle" }}>
                      <VideocamIcon
                        style={{ marginRight: "10px", verticalAlign: "middle" }}
                      />
                      <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                        Online Class
                      </span>
                    </span>
                    <Badge color="info" style={{ verticalAlign: "middle" }}>
                      60 minutes
                    </Badge>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            Please confirm.
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => setModal(false)}>Never Mind</Button>
            <Button color="success" onClick={handleSignUpConfirmClick}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.container}>
          <div className={classes.section}>
            {/* <div className={classes.description}>
                        <h2 className={classes.title}>Here are our upcoming availabilities for 1-on-1 lessons.</h2>
                        <p>Text on overview of 1-on-1 lessons. </p>
                    </div> */}

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.title}>1-on-1 lessons</h2>
                <h4 className={classes.description}>
                  Please use the form below to request for 1-on-1 lessons. We
                  will get in touch as soon as we can. All times listed are
                  Pacific Standard Time, and lessons will reccur weekly unless
                  otherwise indicated.
                </h4>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label="Date"
                        format="MM/dd/yyyy"
                        disablePast="true"
                        maxDate={new Date(2036, 11, 30)}
                        value={selectedDate}
                        onChange={handleDateChange}
                        onMonthChange={handleMonthChange}
                        shouldDisableDate={filterNextFewDays}
                      />
                    </MuiPickersUtilsProvider>
                  </GridItem>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Time</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={hour}
                      onChange={handleHourChange}
                    >
                      {hours.map((hour) => (
                        <MenuItem
                          key={hour.value}
                          value={hour.value}
                          disabled={hour.disabled}
                        >
                          {hour.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <CustomInput
                    labelText="Please tell us about your skill level, and anything else you would like us to know."
                    id="notes"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.textArea,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      onChange: updateInput,
                    }}
                  />
                  <GridItem xs={12} sm={12} md={4}>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={hour === "" || selectedDate === ""}
                      onClick={handleEnrollOpen}
                    >
                      Enroll
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withSnackbar(withRouter(OneOnOneLessonsPage));
