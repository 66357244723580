import React, { useEffect } from "react";

import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import ProductSection from "./Sections/ProductSection.js";
import classNames from "classnames";
import firebase from "util/firebase";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { useLocation } from "react-router-dom";
import { withSnackbar } from "components/withSnackbar";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

function LandingPage(props) {
  const classes = useStyles();
  const { snackbarShowMessage, ...rest } = props;
  const location = useLocation();

  useEffect(() => {
    firebase.analytics().logEvent("landing_page_view");

    console.log(location.pathname); // result: '/secondpage'
    console.log(location.search); // result: '?query=abc'
    console.log(location?.state?.detail); // result: 'some_value'

    if (location?.state?.snackbarOpen === true) {
      snackbarShowMessage(
        location?.state?.snackbarMessage,
        location?.state?.snackbarSeverity
      );
      location.state.snackbarOpen = false;
    }
  }, [location, snackbarShowMessage]);

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="425 Kids Chess"
        leftLinks={<HeaderLeftLinks />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={require("assets/img/landing-chess4.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Let's Play.</h1>
              <h4>
                Chess helps you to concentrate, improve your logic. It teaches
                you to play by the rules and take responsibility for your
                actions, how to problem solve in an uncertain environment.
              </h4>
              <br />
              <Button
                color="info"
                size="lg"
                href="https://en.wikipedia.org/wiki/Chess"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-wikipedia-w" />
                Wikipedia
              </Button>
            </GridItem>
          </GridContainer>
          <span
            style={{ position: "absolute", bottom: "100px", right: "60px" }}
          >
            Photo by{" "}
            <a href="https://unsplash.com/@mparzuchowski?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
              Michał Parzuchowski
            </a>{" "}
            on{" "}
            <a href="https://unsplash.com/s/photos/chess?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
              Unsplash
            </a>
          </span>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withSnackbar(LandingPage);
