import styles from "assets/jss/material-kit-react/components/infoStyle.js";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function InfoArea(props) {
  const classes = useStyles();
  const { title, description, iconColor, vertical, image } = props;
  const iconWrapper = classNames({
    [classes.iconWrapper]: true,
    [classes[iconColor]]: true,
    [classes.iconWrapperVertical]: vertical,
  });
  return (
    <div className={classes.infoArea}>
      <div className={iconWrapper}>
        {/* <div style={{width: '306px', height: '256px'}}> */}
        <div style={{ width: "100%", height: "auto" }}>
          <img src={image} style={{ width: "100%", height: "auto" }} />
        </div>
      </div>
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title}</h4>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
}

InfoArea.defaultProps = {
  iconColor: "gray",
};

InfoArea.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  vertical: PropTypes.bool,
};
