import "firebase/analytics";
import "firebase/auth";

import "./config";

import firebase from "firebase/app";

import config from "./config";

const firebaseConfig = {
  //apiKey: process.env.REACT_APP_FIREBASE_KEY,
  //authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  //databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  //projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  //storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  //messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  //appId: process.env.REACT_APP_FIREBASE_APP_ID,
  //measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  apiKey: config.apiKey,
  authDomain: config.authDomain,
  databaseURL: config.databaseURL,
  projectId: config.projectId,
  storageBucket: config.storageBucket,
  messagingSenderId: config.messagingSenderId,
  appId: config.appId,
  measurementId: config.measurementId,
};

firebase.initializeApp(firebaseConfig);

export default firebase;
