const isEmpty = (string) => {
  if (string && string.trim() !== "") return false;
  else return true;
};

exports.validateLoginData = (data) => {
  let errors = {};
  if (isEmpty(data.email)) errors.email = "Must not be empty";
  if (isEmpty(data.password)) errors.password = "Must not be empty";
  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false,
  };
};

const isEmail = (email) => {
  const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email.match(emailRegEx)) return true;
  else return false;
};

exports.validateSignUpData = (data) => {
  let errors = {};

  if (isEmpty(data.email)) {
    errors.email = "Must not be empty";
  } else if (!isEmail(data.email)) {
    errors.email = "Must be valid email address";
  }

  if (isEmpty(data.firstName)) errors.firstName = "Must not be empty";
  if (isEmpty(data.lastName)) errors.lastName = "Must not be empty";
  if (isEmpty(data.password)) errors.password = "Must not be empty";
  if (data.password !== data.confirmPassword)
    errors.confirmPassword = "Passowrds must be the same";
  if (isEmpty(data.username)) errors.username = "Must not be empty";

  if (isEmpty(data.gradeLevel)) errors.gradeLevel = "Must not be empty";

  if (isEmpty(data.parentFirstName))
    errors.parentFirstName = "Must not be empty";
  if (isEmpty(data.parentLastName)) errors.parentLastName = "Must not be empty";

  if (isEmpty(data.parentEmail)) {
    errors.parentEmail = "Must not be empty";
  } else if (!isEmail(data.parentEmail)) {
    errors.parentEmail = "Must be valid email address";
  }

  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false,
  };
};
