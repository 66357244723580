import customInputStyle from "assets/jss/material-kit-react/components/customInputStyle";
import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withSnackbar } from "components/withSnackbar";
import { useAuth } from "contexts/AuthContext";
import React, { useState } from "react";
import { withRouter } from "react-router";
import API from "util/api";
import { validateSignUpData } from "util/validators";

import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import Phone from "@material-ui/icons/Phone";
import School from "@material-ui/icons/School";
import Autocomplete from "@material-ui/lab/Autocomplete";

const schools = [
  { name: "Bellevue High School" },
  { name: "Interlake High School" },
  { name: "Newport High School" },
  { name: "Sammamish High School" },
  { name: "Chinook Middle School" },
  { name: "Highland Middle School" },
  { name: "Odle Middle School" },
  { name: "Tillicum Middle School" },
  { name: "Tyee Middle School" },
  { name: "Ardmore Elementary School" },
  { name: "Bennett Elementary School" },
  { name: "Cherry Crest Elementary School" },
  { name: "Clyde Hill Elementary School" },
  { name: "Eastgate Elementary School" },
  { name: "Enatai Elementary School" },
  { name: "Lake Hills Elementary School" },
  { name: "Medina Elementary School" },
  { name: "Newport Heights Elementary School" },
  { name: "Phantom Lake Elementary School" },
  { name: "Sherwood Forest Elementary School" },
  { name: "Somerset Elementary School" },
  { name: "Spiritridge Elementary School" },
  { name: "Stevenson Elementary School" },
  { name: "Woodridge Elementary School" },
  { name: "Wilburton Elementary" },
  { name: "Bellevue Big Picture School" },
  { name: "International School" },
  { name: "Puesta del Sol Elementary School" },
  { name: "Jing Mei Elementary School" },
];

const styles = {
  ...loginPageStyle,
  ...customInputStyle,
  inputRoot: {
    color: "purple",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple",
    },
    fontSize: "14px",
  },
};

// const styles = {
//     clearfix: {
//       "&:after,&:before": {
//         display: "table",
//         content: '" "'
//       },
//       "&:after": {
//         clear: "both"
//       }
//     }
//   };

const useStyles = makeStyles(styles);

function Signup(props) {
  const [errors, setErrors] = useState([]);
  const [, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const { signup } = useAuth();

  const classes = useStyles();

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    props.loadingHandler(true);

    const newUserData = {
      firstName: form.firstName,
      lastName: form.lastName,
      phoneNumber: form.phoneNumber,
      email: form.email,
      school: form.school,
      gradeLevel: form.gradeLevel,
      parentFirstName: form.parentFirstName,
      parentLastName: form.parentLastName,
      parentEmail: form.parentEmail,
      password: form.password,
      confirmPassword: form.confirmPassword,
      isInstructor: false,
      username: form.email,
    };

    const { valid, errors } = validateSignUpData(newUserData);
    setErrors({ errors: errors });

    if (!valid) {
      console.log(errors);
      props.snackbarShowMessage("Sign up failed. Please try again!", "error");
      props.loadingHandler(false);
      return;
    }

    signup(newUserData.email, newUserData.password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;

        return user.getIdToken().then((idToken) => {
          user.sendEmailVerification().then(() => {
            // Email sent.

            API.defaults.headers.common = {
              Authorization: `Bearer ${idToken}`,
            };
            return API.post("/signup", newUserData).then(() => {
              setLoading(false);
              props.loadingHandler(false);

              // props.history.push({
              //     pathname: "/",
              //     state: {
              //         snackbarOpen: true,
              //         snackbarMessage: "Sign up successful!",
              //         snackbarSeverity: "success",
              //     },
              // });
              props.history.push("/email-verification");
            });
          });
        });
      })
      .catch((error) => {
        console.log(error);

        if (error?.response?.data?.length === 0) {
          setErrors(error.response.data);
        }
        setLoading(false);
        props.snackbarShowMessage("Sign up failed. Please try again!", "error");
        props.loadingHandler(false);
      });
  };

  return (
    <GridItem xs={12} sm={12} md={8}>
      <Card>
        <form className={classes.form}>
          <CardHeader color="primary" className={classes.cardHeader}>
            <h4>Signup</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="First Name"
                  id="firstName"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  inputProps={{
                    type: "text",
                    required: true,
                    //helperText: errors.firstName,
                    placeholder: errors.firstName ? errors.firstName : "",
                    error: errors.firstName ? true : false,
                    endAdornment: (
                      <InputAdornment position="end">
                        <People className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    onChange: (e) => handleChange(e),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Last Name"
                  id="lastName"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  inputProps={{
                    type: "text",
                    required: true,
                    placeholder: errors.lastName ? errors.lastName : "",
                    error: errors.lastName ? true : false,
                    endAdornment: (
                      <InputAdornment position="end">
                        <People className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    onChange: (e) => handleChange(e),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Phone Number (optional)"
                  id="phoneNumber"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "tel",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Phone className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    onChange: (e) => handleChange(e),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Email Address"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  inputProps={{
                    type: "email",
                    required: true,
                    placeholder: errors.email ? errors.email : "",
                    error: errors.email ? true : false,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    onChange: (e) => handleChange(e),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Autocomplete
                  classes={classes}
                  id="school"
                  freeSolo
                  options={schools.map((option) => option.name)}
                  renderOption={(option) => (
                    <Typography style={{ fontSize: "14px" }}>
                      {option}
                    </Typography>
                  )}
                  disableClearable
                  forcePopupIcon={false}
                  onInputChange={(event, newInputValue) => {
                    setForm({
                      ...form,
                      school: newInputValue,
                    });
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="School (optional)"
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <School className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        // style: { fontSize: '14px' }
                      }}
                    />
                  )}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Grade Level"
                  id="gradeLevel"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  inputProps={{
                    type: "number",
                    required: true,
                    placeholder: errors.gradeLevel ? errors.gradeLevel : "",
                    error: errors.gradeLevel ? true : false,
                    max: 12,
                    min: 1,
                    endAdornment: (
                      <InputAdornment position="end">
                        <School className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    onChange: (e) => handleChange(e),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Parent First Name"
                  id="parentFirstName"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  inputProps={{
                    type: "text",
                    required: true,
                    placeholder: errors.parentFirstName
                      ? errors.parentFirstName
                      : "",
                    error: errors.parentFirstName ? true : false,
                    endAdornment: (
                      <InputAdornment position="end">
                        <People className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    onChange: (e) => handleChange(e),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Parent Last Name"
                  id="parentLastName"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  inputProps={{
                    type: "text",
                    required: true,
                    placeholder: errors.parentLastName
                      ? errors.parentLastName
                      : "",
                    error: errors.parentLastName ? true : false,
                    endAdornment: (
                      <InputAdornment position="end">
                        <People className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    onChange: (e) => handleChange(e),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Parent Email Address"
                  id="parentEmail"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  inputProps={{
                    type: "email",
                    required: true,
                    placeholder: errors.parentEmail ? errors.parentEmail : "",
                    error: errors.parentEmail ? true : false,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    onChange: (e) => handleChange(e),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  inputProps={{
                    type: "password",
                    required: true,
                    placeholder: errors.password ? errors.password : "",
                    error: errors.password ? true : false,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputIconsColor}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                    onChange: (e) => handleChange(e),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Confirm Password"
                  id="confirmPassword"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  inputProps={{
                    type: "password",
                    required: true,
                    placeholder: errors.confirmPassword
                      ? errors.confirmPassword
                      : "",
                    error: errors.confirmPassword ? true : false,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputIconsColor}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                    onChange: (e) => handleChange(e),
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button simple color="primary" size="lg" onClick={handleSubmit}>
              Sign up
            </Button>
          </CardFooter>
          <CardFooter className={classes.cardFooter}>
            <Link href="login-page">{"Already have an account? Sign in"}</Link>
          </CardFooter>
        </form>
      </Card>
    </GridItem>
  );
}
export default withSnackbar(withRouter(Signup));
