import { cardTitle } from "assets/jss/material-kit-react.js";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import modalStyle from "assets/jss/material-kit-react/modalStyle.js";
import Badge from "components/Badge/Badge.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withSnackbar } from "components/withSnackbar";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import API from "util/api";
import { authMiddleWare } from "util/auth";
import firebase from "util/firebase";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import VideocamIcon from "@material-ui/icons/Videocam";

import CardFooter from "./Card/CardFooter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styles = {
  ...imagesStyles,
  ...modalStyle,
  cardTitle,
};

const useStyles = makeStyles(styles);

const parseLines = (value) => value.replace(/(\\n)/g, "\n");

function Lessons(props) {
  const [lessons, setLessons] = useState([]);
  const [lessonId, setLessonId] = useState("");
  const [lessonStatus, setLessonStatus] = useState("");
  const [title, setTitle] = useState("");
  const [, setDescription] = useState("");
  const [classStartTime, setClassStartTime] = useState("");
  const [classDurationInMinutes, setClassDurationInMinutes] = useState("");
  const [modal, setModal] = useState(false);

  const classes = useStyles();
  const test = classes.modalTitle;
  console.log(`modalTitle: ${test}`);

  useEffect(() => {
    props.loadingHandler(true);
    API.get(`/lessons/${props.lessontype}`)
      .then((response) => {
        setLessons(response.data);
        props.loadingHandler(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.lessontype]);

  const handleSignUpClickOpen = (data) => {
    authMiddleWare(props.history);
    setLessonId(data.lesson.lessonId);
    setTitle(data.lesson.title);
    setDescription(data.lesson.description);
    setClassStartTime(data.lesson.classStartTime);
    setClassDurationInMinutes(data.lesson.classDurationInMinutes);
    setModal(true);
  };

  const handleSignUpConfirmClick = (data) => {
    authMiddleWare(props.history);

    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then((idToken) => {
        localStorage.setItem("AuthToken", `Bearer ${idToken}`);

        const authToken = localStorage.getItem("AuthToken");

        API.defaults.headers.common = { Authorization: `${authToken}` };
        return API.put(`/lesson/signup/${data.lessonId}`).then(() => {
          setModal(false);
          setLessonStatus("Signed up");
          props.snackbarShowMessage("Sign up successful!", "success");
        });
      })
      .catch((err) => {
        console.log(err);
        props.snackbarShowMessage("Sign up failed!", "error");
      });
  };

  // if (uiLoading === true) {
  //     return (
  //         <main>
  //             <div />
  //             {uiLoading && <CircularProgress size={150} />}
  //         </main>
  //     );
  // } else {
  return (
    <GridContainer>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        onClick={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>
            {props.lessontype === "group" ? "Group" : "One-on-one"} Lesson Sign
            Up
          </h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          Hi {localStorage.getItem("FirstName")}, you are about to sign up for
          <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={12}>
              <Card style={{ width: "30rem" }}>
                <CardBody>
                  <h4 className={classes.cardTitle}>{title}</h4>
                  <span style={{ display: "inline", alignItems: "middle" }}>
                    <VideocamIcon
                      style={{ marginRight: "10px", verticalAlign: "middle" }}
                    />
                    <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                      Online Class
                    </span>
                  </span>
                  <Badge color="info" style={{ verticalAlign: "middle" }}>
                    {classDurationInMinutes} minutes
                  </Badge>
                  {/* <Badge color="info" style={{display: "inline-block"}}>{lesson.classDurationInMinutes} minutes</Badge>            */}
                  <p>
                    {dayjs(classStartTime).format("dddd, MMM D YYYY h:mm A")}
                  </p>

                  {/* <h5>Class Description</h5>
                                <p>{description}</p> */}
                </CardBody>
              </Card>{" "}
            </GridItem>
          </GridContainer>
          Please confirm.
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setModal(false)}>Never Mind</Button>
          <Button
            color="success"
            onClick={() => handleSignUpConfirmClick({ lessonId })}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {lessons.map((lesson) => (
        <GridItem key={lesson.lessonId} xs={12} sm={12} md={6}>
          <Card style={{ width: "30rem", height: "40rem" }}>
            <CardBody>
              <h4 className={classes.cardTitle}>{lesson.title}</h4>
              <span style={{ display: "inline", alignItems: "middle" }}>
                <VideocamIcon
                  style={{ marginRight: "10px", verticalAlign: "middle" }}
                />
                <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                  Online Class
                </span>
              </span>
              <Badge color="info" style={{ verticalAlign: "middle" }}>
                {lesson.classDurationInMinutes} minutes
              </Badge>
              {/* <Badge color="info" style={{display: "inline-block"}}>{lesson.classDurationInMinutes} minutes</Badge>            */}
              <p>
                {dayjs(lesson.classStartTime).format("dddd, MMM D YYYY h:mm A")}
              </p>

              <h5>Class Description</h5>
              <p style={{ whiteSpace: "pre-line" }}>
                {parseLines(lesson.description)}
              </p>
            </CardBody>
            <CardFooter>
              <Button
                color={
                  lesson.students.includes(localStorage.getItem("UserName")) ||
                  (lessonStatus === "Signed up" && lessonId === lesson.lessonId)
                    ? "success"
                    : "primary"
                }
                onClick={() => handleSignUpClickOpen({ lesson })}
                disabled={
                  lesson.students.includes(localStorage.getItem("UserName")) ||
                  (lessonStatus === "Signed up" && lessonId === lesson.lessonId)
                    ? true
                    : lesson.status === "Closed"
                    ? true
                    : false
                }
                style={{}}
              >
                {lesson.students.includes(localStorage.getItem("UserName")) ||
                (lessonStatus === "Signed up" && lessonId === lesson.lessonId)
                  ? "Signed up"
                  : lesson.status === "Closed"
                  ? "Closed"
                  : "Sign up"}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      ))}
    </GridContainer>
  );
  //}
}

export default withSnackbar(withRouter(Lessons));
